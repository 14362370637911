import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Menubar = ({ isSideMenuOpen, toggleIsSideMenuOpenModal, isSelected }) => {
  const { t } = useTranslation();
  const [tabMenuViews, setTabMenuViews] = useState([
    { title: "dashboard", value: "dashboard", isActive: true },
    { title: "wallet", value: "wallet", isActive: false },
    { title: "withdrawal", value: "withdrawal", isActive: false },
    { title: "transaction", value: "transaction", isActive: false },
    { title: "booking", value: "booking", isActive: false },
    { title: "schedule", value: "schedule", isActive: false },
    { title: "services", value: "services", isActive: false },
    { title: "professionals", value: "professionals", isActive: false },
    { title: "promocode", value: "promocode", isActive: false },
  ]);

  const handleTabClick = (tab) => {
    const filterTabList = tabMenuViews.map((el) =>
      el.title === tab ? { ...el, isActive: true } : { ...el, isActive: false }
    );
    setTabMenuViews(filterTabList)
  }
  
  return (
    <>
      <div onClick={toggleIsSideMenuOpenModal} id="app-sidepanel" className={`app-sidepanel ${!isSideMenuOpen ? 'sidepanel-hidden' : 'sidepanel-visible'}`} style={{ left: 0 }}>
        <div id="sidepanel-drop" className="sidepanel-drop"></div>
        <div className="sidepanel-inner d-flex flex-column">
          <span
            id="sidepanel-close"
            className="sidepanel-close d-xl-none"
            onClick={toggleIsSideMenuOpenModal}
          >
            &times;
          </span>
          <div className="app-branding">
            <a className="app-logo" href="#!">
              <img
                className="logo-icon me-2"
                src="assets/img/app-logo.png"
                alt="logo"
              />
              <span className="logo-text">{t("SOUL_BUSINESS")}</span>
            </a>
          </div>
          <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
            <ul
              className="app-menu list-unstyled accordion"
              id="menu-accordion"
            >
              <li className="nav-item" onClick={() => handleTabClick("dashboard")}>
                <Link className="nav-link" to="/dashboard">
                  <span className={`nav-icon ${tabMenuViews[0].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="bi bi-house-door"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                      />
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[0].isActive ? "m-selected" : ""
                    }`} >{t("KPI")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("wallet")}>
                <Link className="nav-link " to="/wallet">
                  <span className={`nav-icon ${tabMenuViews[1].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M30.4,9.8c-0.6-0.8-1.4-1.3-2.6-1.2c0-1.1,0-2.1,0-3.1c0-2.3-1-3.3-3.3-3.3c-6.1,0-12.1,0.1-18.2,0c-2.2,0-3.8,0.6-4.8,2.4
                          C1.3,5.1,1.1,5.7,1.1,6.3v19.2c0,1.1,0.4,2.2,1.2,3c1,0.9,2.3,1.3,3.9,1.3c6.9-0.1,13.9-0.1,20.8,0c0.8,0,1.5-0.1,2.1-0.3
                          c1-0.4,1.6-1.4,1.6-2.5V11.1C30.9,10.6,30.7,10.2,30.4,9.8z M3,6.3c0-1.3,1.1-2.1,2.7-2.1c6.3,0,12.5,0,18.8,0
                          c1.1,0,1.5,0.4,1.4,1.4c0,1,0,1.9,0,3h-9.1c-3.7,0-7.4,0-11.1,0C4.1,8.6,3,7.6,3,6.3z M27.8,27.9c-1.5,0-3.1,0-4.6,0
                          c-5.7,0-11.5,0-17.2,0c-2.1,0-2.9-0.8-2.9-2.9c0-5,0-10,0-14.8c1.5,0.1,3,0.3,4.5,0.3c6.6,0,13.2,0,19.9,0c1.5,0,1.6,0.1,1.6,1.7
                          c0,0.9,0,1.9,0,3c-2.5,0-4.8,0-7.2,0c-2.2,0-3.9,1.7-4.2,3.8c-0.3,2.1,1,4.1,3,4.6c0.7,0.2,1.5,0.2,2.3,0.2c2,0,3.9,0,6,0
                          c0,1.1,0,2,0,2.9C29,27.5,28.7,27.9,27.8,27.9z M29,16.9v4.8c-2.6,0-5.1,0.1-7.6,0c-1.1-0.1-1.9-1.3-1.9-2.5c0.1-1.2,1-2.3,2.3-2.3
                          C24.2,16.9,26.5,16.9,29,16.9z"/>
                        <path d="M22.1,18.1c-0.8-0.1-1.5,0.6-1.4,1.4c0.1,0.6,0.5,1,1.1,1.1c0.8,0.1,1.5-0.6,1.4-1.4C23.1,18.7,22.7,18.2,22.1,18.1z" />
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[1].isActive ? "m-selected" : ""
                    }`} >{t("WALLET")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("withdrawal")}>
                <Link className="nav-link " to="/withdrawal">
                  <span className={`nav-icon ${tabMenuViews[2].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor"
                    >
                      <g>
                        <path d="M31.8,1.8c-0.2-1-1.1-1.6-2.3-1.6c-9,0-18.1,0-27.1,0c-0.1,0-0.2,0-0.3,0c-0.9,0-1.7,0.6-1.9,1.5C0.1,2.1,0.1,2.5,0.1,3
                        c0,0.8,0.4,1.4,1.1,1.8c0.3,0.1,0.3,0.3,0.3,0.6c0,1.5,0,3.1,0,4.6c0,1,0.6,1.8,1.5,2c0.3,0.1,0.6,0.1,1,0.1c1.3,0,2.6,0,3.9,0v0.7
                        c0,5.6,0,11.1,0,16.7c0,1.5,0.8,2.4,2.3,2.4c3.8,0,7.7,0,11.5,0c0.2,0,0.5,0,0.7-0.1c1-0.3,1.6-1.1,1.6-2.3c0-5.6,0-11.2,0-16.8
                        v-0.6h0.6c1.2,0,2.3,0,3.5,0c1.4,0,2.3-0.9,2.3-2.3c0-1.5,0-3,0-4.5c0-0.1,0.1-0.4,0.2-0.4C32,4.2,32,2.9,31.8,1.8z M9.9,30.5
                        c-0.4,0-0.6-0.3-0.6-0.7c0-0.9,0-1.8,0-2.7c1.4-0.1,3.3,1.8,3.4,3.4C11.8,30.5,10.8,30.5,9.9,30.5z M17.4,30.5c-1.1,0-2.1,0-3.2,0
                        c-0.2-1.5-0.9-2.7-2-3.7c-0.7-0.6-1.5-1-2.5-1.2c-0.2,0-0.4-0.1-0.4-0.4c0-3.8,0-7.7,0-11.5c0-0.1,0-0.1,0-0.2
                        c2.7-0.5,4.3-2.2,4.9-4.9c1.1,0,2.2,0,3.3,0c0.1,0,0.3,0.1,0.3,0.2c0.5,2.6,2.2,4.1,4.8,4.7v12c-0.1,0-0.3,0.1-0.5,0.1
                        c-2.3,0.6-3.8,2.1-4.3,4.4C17.8,30.4,17.7,30.5,17.4,30.5z M9.3,12V8.6h3.4C12.7,10.1,10.9,11.9,9.3,12z M19.3,8.6h3.4V12
                        C21.1,11.9,19.3,10.1,19.3,8.6z M22.7,29.9c0,0.3-0.2,0.6-0.6,0.6c-0.9,0-1.9,0-2.8,0c0.1-1.6,1.8-3.3,3.4-3.4
                        C22.7,28,22.7,28.9,22.7,29.9z M29,8.2c0,0.6,0,1.1,0,1.7c0,0.4-0.2,0.7-0.6,0.7c-1.4,0-2.8,0-4.3,0V8.6c0.7,0,1.3,0,1.9,0
                        c0.5,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.7-0.8-0.7c-6.7,0-13.5,0-20.2,0C5.4,7.2,5,7.5,5,7.9c0,0.4,0.3,0.7,0.8,0.7c0.6,0,1.3,0,1.9,0
                        v2.1H5.3c-0.5,0-1.1,0-1.6,0c-0.4,0-0.7-0.2-0.7-0.6c0-1.7,0-3.3,0-5H29V8.2z M30.3,3.3c0,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.5,0
                        c-8.9,0-17.8,0-26.8,0c-0.3,0-0.6,0-0.8-0.2C1.3,3.1,1.5,2.5,1.6,2C1.6,1.6,2,1.5,2.4,1.5c4.5,0,9.1,0,13.6,0c4.5,0,8.9,0,13.4,0
                        c0.4,0,0.8,0,0.9,0.4C30.4,2.4,30.4,2.9,30.3,3.3z"/>
                        <path d="M16,16.4c-1.8,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.1,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2C19.2,17.8,17.7,16.4,16,16.4z M16,21.3
                        c-1,0-1.8-0.8-1.7-1.8c0-1,0.8-1.8,1.8-1.7c1,0,1.7,0.8,1.7,1.8C17.7,20.5,16.9,21.3,16,21.3z"/>
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[2].isActive ? "m-selected" : ""
                    }`} >{t("WITHDRAWAL")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("transaction")}>
                <Link className="nav-link " to="/transaction">
                  <span className={`nav-icon ${tabMenuViews[3].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <g>
                        <path d="M26.1,13.4c-1.3-1.3-2.4-2.4-3.7-3.7c-0.5-0.4-1-0.5-1.4-0.1s-0.3,0.9,0.1,1.4c0.5,0.5,0.9,0.9,1.3,1.3
                          c0.3,0.3,0.5,0.5,0.8,0.8h-0.4c-4.2,0-8.3,0-12.4,0c-0.6,0-1.1,0.4-1.1,0.9c0,0.5,0.4,0.9,1.1,0.9c5,0,10,0,15,0
                          c0.5,0,0.8-0.2,0.9-0.6C26.6,14.1,26.4,13.7,26.1,13.4z"/>
                        <path d="M21.6,16.9c-2.9,0-5.8,0-8.7,0c-2.1,0-4.2,0-6.3,0c-0.4,0-0.7,0.2-0.9,0.6c-0.2,0.4-0.1,0.7,0.2,1.1
                          c1.3,1.3,2.6,2.6,3.8,3.8c0.2,0.2,0.5,0.3,0.8,0.2c0.4-0.1,0.7-0.3,0.7-0.7c0.1-0.4,0.1-0.7-0.2-1c-0.7-0.7-1.4-1.4-2.1-2.1v-0.1
                          h12.6c0.1,0,0.2,0,0.3,0c0.4-0.1,0.7-0.3,0.8-0.7C22.8,17.6,22.3,16.9,21.6,16.9z"/>
                        <path d="M16,0C7.2,0,0,7.2,0,16s7.1,16,16,16s16-7.1,16-16S24.8,0,16,0z M16,30.5C8,30.5,1.5,24,1.5,16S8,1.5,16,1.5
                          S30.5,8,30.5,16S24,30.5,16,30.5z"/>
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[3].isActive ? "m-selected" : ""
                    }`} >{t("TRANSACTIONS")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("booking")}>
                <Link className="nav-link " to="/booking">
                  <span className={`nav-icon ${tabMenuViews[4].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <g>
                        <path d="M30.5,8.2c0-1.9-1.3-3.5-3.1-3.9l-0.2-0.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.2-0.8-0.4-1.2c-0.3-0.6-0.9-0.9-1.7-1
                          c-0.6,0-1,0.1-1.5,0.5c-0.7,0.6-0.8,1.3-0.8,2.1h-1.5c0-0.1,0-0.3,0-0.4c0-1.2-0.9-2.1-2.1-2.1l0,0c-1.2,0-2.1,1-2.1,2.2
                          c0,0.1,0,0.3,0,0.4h-1.5c0-0.1,0-0.3,0-0.4c0-1.2-0.9-2.2-2.1-2.2s-2.1,0.9-2.2,2.2c0,0.1,0,0.3,0,0.4H9.3c0-0.6,0-1.2-0.5-1.8
                          C8.4,1.7,7.7,1.4,7,1.4C5.7,1.5,5.1,2.4,4.9,4.2H4.8c-1.9,0.4-3.3,2.1-3.3,4c0,6.7,0,12.7,0,18.2c0,2.2,1.9,4.1,4.1,4.1
                          c3.5,0,6.9,0,10.4,0s7,0,10.4,0c2.2,0,4.1-1.9,4.1-4.1C30.5,21,30.5,15.3,30.5,8.2z M29,11.1v4.4c0,3.5,0,7.1,0,10.5
                          c0,1.8-1.1,2.8-2.8,2.8H5.8c-1.8,0-2.8-1.1-2.8-2.8V11.1C3,11.1,29,11.1,29,11.1z M7.1,3c0.4,0,0.7,0.3,0.7,0.7c0,0.3,0,0.6,0,0.8
                          v0.4v0.4c0,0.3,0,0.6,0,0.8c0,0.3-0.3,0.6-0.7,0.6l0,0c-0.4,0-0.7-0.3-0.7-0.6c0-0.8,0-1.7,0-2.4C6.5,3.3,6.8,3,7.1,3L7.1,3z
                          M13.7,6c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.3,0-0.6,0-0.8V4.8V4.4c0-0.3,0-0.6,0-0.7l0,0C12.4,3.3,12.6,3,13,3
                          l0,0c0.4,0,0.7,0.3,0.7,0.7C13.8,4.4,13.8,5.3,13.7,6z M22.7,6.1c0.1,1.2,1,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0-0.1,0-0.3,0-0.4
                          c0.4,0.1,0.7,0.3,0.9,0.5c0.6,0.5,0.9,1,0.9,1.8c0,0.4,0,0.8,0,1.2c0,0.1,0,0.3,0,0.4H3c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0.1-1.5
                          c0.2-0.9,0.9-1.7,1.8-2c0,0.1,0,0.2,0,0.3c0,1.2,0.9,2.1,2.1,2.1l0,0c1.2,0,2.1-0.9,2.1-2.1c0-0.1,0-0.3,0-0.4h1.6
                          c0,0.2,0,0.3,0,0.5c0,1.2,0.9,2.1,2.1,2.1l0,0c1.2,0,2.1-0.9,2.1-2.1c0-0.2,0-0.3,0-0.5h1.5v0.3c0,1.3,0.9,2.2,2.1,2.2l0,0
                          c1.2,0,2.1-0.9,2.1-2.2c0-0.1,0-0.2,0-0.4h1.6l0,0C22.7,5.8,22.7,5.9,22.7,6.1z M24.2,4.5c0-0.3,0-0.6,0-0.8c0-0.4,0.3-0.7,0.7-0.7
                          l0,0c0.4,0,0.7,0.3,0.7,0.7c0,0.7,0,1.6,0,2.3c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7V5.1l0,0V4.5z M19.6,3.7
                          c0,0.3,0,0.6,0,0.7v0.4v0.4c0,0.3,0,0.5,0,0.7c0,0.4-0.3,0.7-0.7,0.7l0,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.7,0-1.5,0-2.3
                          c0-0.4,0.3-0.7,0.7-0.7l0,0C19.4,3,19.6,3.3,19.6,3.7z"/>
                        <path d="M7,18.8c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0c0.9,0,1.6-0.7,1.6-1.6c0-0.3,0-0.5,0-0.7v-0.3v-0.4c0-0.3,0-0.6,0-0.7
                          c0-0.8-0.7-1.6-1.6-1.6c-0.7,0-1.6,0-2.3,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.7,0,1.5,0,2.1C5.4,18,6.1,18.8,7,18.8z M7,16.1v-0.3
                          c0-0.2,0-0.5,0-0.7c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.2,0c0.4,0,0.7,0,1,0c0.4,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2
                          c0,0.7,0,1.3,0,2.1c0,0.1,0,0.2-0.2,0.2H7.1l0,0c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.2c0-0.3,0-0.5,0-0.7V16.1z"/>
                        <path d="M7,26.2c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0c0.8,0,1.6-0.7,1.6-1.6c0-0.3,0-0.5,0-0.7v-0.5v-0.2c0-0.3,0-0.6,0-0.8
                          c0-0.8-0.7-1.5-1.5-1.5s-1.6,0-2.3,0c-0.8,0-1.5,0.7-1.5,1.6c0,0.7,0,1.5,0,2.3C5.5,25.4,6.1,26.2,7,26.2z M9.3,24.4
                          c0,0.1,0,0.2,0,0.2s-0.1,0-0.2,0c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6,0-0.8,0H7.1c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.2c0-0.7,0-1.3,0-2.1
                          l0,0c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.2,0l0,0h2.1c0.1,0,0.2,0,0.2,0.2v1.2v0.1C9.3,23.9,9.3,24.2,9.3,24.4z"/>
                        <path d="M25.1,20.8c-0.7,0-1.5,0-2.3,0c-0.8,0-1.6,0.7-1.6,1.6c0,0.7,0,1.5,0,2.3s0.7,1.5,1.6,1.6c0.3,0,0.6,0,0.7,0h0.1h0.6
                          c0.1,0,0.2,0,0.4,0s0.3,0,0.5,0c0.9,0,1.6-0.7,1.6-1.6c0-0.7,0-1.5,0-2.2C26.6,21.4,25.9,20.8,25.1,20.8z M22.7,23.5v-1
                          c0-0.1,0-0.2,0.2-0.2H25l0,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v2.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0-0.2,0h-0.2c-0.6,0-1.2,0-1.8,0
                          c-0.1,0-0.2,0-0.2,0s0-0.1,0-0.2c0-0.2,0-0.5,0-0.7L22.7,23.5z"/>
                        <path d="M15,18.8c0.4,0,0.7,0,1,0c0.4,0,0.7,0,1,0c0.9,0,1.7-0.7,1.7-1.6c0-0.7,0-1.5,0-2.2c0-0.9-0.7-1.6-1.7-1.6
                          c-0.3,0-0.5,0-0.7,0h-0.4h-0.4c-0.3,0-0.5,0-0.7,0c-0.9,0-1.6,0.7-1.7,1.7c0,0.7,0,1.5,0,2.1C13.3,18.1,14,18.8,15,18.8z
                          M16.3,17.3H16h-0.3c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.2,0s0-0.1,0-0.2c0-0.7,0-1.3,0-2.1c0-0.1,0-0.2,0.2-0.2c0.7,0,1.4,0,2.1,0
                          c0.1,0,0.2,0,0.2,0.2c0,0.7,0,1.3,0,2.1c0,0,0,0.1,0,0.2c0,0-0.1,0-0.2,0s-0.3,0-0.4,0C16.5,17.3,16.4,17.3,16.3,17.3z"/>
                        <path d="M17,20.8c-0.7,0-1.4,0-2.1,0c-0.9,0-1.7,0.7-1.7,1.6c0,0.7,0,1.5,0,2.2c0,0.9,0.7,1.6,1.7,1.6c0.3,0,0.5,0,0.7,0h0.3h0.4
                          c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0c0.9,0,1.7-0.7,1.7-1.6c0-0.7,0-1.5,0-2.1C18.7,21.4,18,20.8,17,20.8z M17.2,22.4v0.3
                          c0,0.6,0,1.1,0,1.7c0,0.2,0,0.2-0.2,0.2l0,0c-0.3,0-0.7,0-0.9,0c-0.4,0-0.7,0-1.1,0l0,0c-0.1,0-0.2,0-0.2-0.2c0-0.7,0-1.3,0-2
                          c0-0.1,0-0.2,0-0.2s0.1,0,0.2,0l0,0c0.2,0,0.5,0,0.7,0h0.3h0.3c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.2,0C17.2,22.3,17.2,22.3,17.2,22.4
                          z"/>
                        <path d="M22.6,18c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.7-0.3c0.3-0.3,0.6-0.6,0.8-0.8l0.7-0.7c0.4-0.4,0.7-0.7,1-1.1
                          c0.2-0.2,0.3-0.6,0.2-0.8C26.5,14.2,26.3,14,26,14c-0.3,0-0.6,0.2-0.7,0.3c-0.6,0.6-1.1,1.1-1.7,1.7l-0.5,0.5L23,16.3
                          c-0.2-0.3-0.5-0.5-0.7-0.7c-0.2-0.2-0.5-0.3-0.7-0.3l0,0c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.3-0.4,0.7,0.1,1.1S22.2,17.5,22.6,18z"/>
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[4].isActive ? "m-selected" : ""
                    }`} >{t("BOOKINGS")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("schedule")}>
                <Link className="nav-link " to="/schedule">
                  <span className={`nav-icon ${tabMenuViews[5].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <g>
                        <path d="M31.5,21.8c-0.6-1.8-1.7-3.2-3.2-4.2c-0.2-0.1-0.2-0.2-0.2-0.4c0-3.9,0-7.8,0-11.7c0-1.7-1-3-2.6-3.4C25.1,2,24.8,2,24.5,2
                          c-0.5,0-1.1,0-1.7,0c0-0.7,0.2-1.5-0.7-1.9h-0.5C20.8,0.5,21,1.2,21,1.9h-3.3c0-0.7,0.2-1.5-0.7-1.8h-0.5c-0.9,0.4-0.7,1.1-0.7,1.8
                          h-3.3c0-0.7,0.2-1.5-0.7-1.8h-0.5c-0.3,0.2-0.6,0.4-0.7,0.8c0,0.3,0,0.7,0,1.1H7.2c0-0.7,0.2-1.5-0.7-1.8H6C5.1,0.5,5.3,1.3,5.3,2
                          C4.8,2,4.2,2,3.7,2c-2-0.1-3.4,1.4-3.6,2.9c0,0,0,0.1,0,0.1v19.4c0,0.2,0.1,0.3,0.1,0.5c0.4,1.2,1.5,1.9,2.8,1.9c4.5,0,9,0,13.4,0
                          h0.4c0.1,0.1,0.1,0.2,0.2,0.4c1.2,2.7,3.3,4.3,6.3,4.7c0.1,0,0.1,0,0.2,0.1h1.4c0.6-0.1,1.2-0.2,1.7-0.4c3.1-1.1,4.8-3.3,5.3-6.5
                          c0-0.1,0-0.1,0.1-0.2v-1.4C31.8,22.9,31.7,22.4,31.5,21.8z M2,5.4c0-1,0.7-1.6,1.6-1.6c0.6,0,1.1,0,1.7,0c0,0.3,0,0.6,0,0.9
                          c0,0.6,0.4,1,0.9,1c0.5,0,0.9-0.4,0.9-1c0-0.3,0-0.6,0-0.9h3.4c0,0.3,0,0.6,0,0.9c0,0.6,0.4,1,0.9,1c0.5,0,0.9-0.4,0.9-1
                          c0-0.3,0-0.6,0-0.9h3.4c0,0.3,0,0.5,0,0.8c0,0.6,0.4,1,0.9,1c0.5,0,0.9-0.4,0.9-1c0-0.3,0-0.5,0-0.8H21c0,0.3,0,0.6,0,0.9
                          c0,0.6,0.4,1,0.9,1c0.5,0,0.9-0.4,0.9-1c0-0.3,0-0.6,0-0.8c0.7,0,1.5-0.1,2.2,0c0.7,0.1,1.1,0.7,1.2,1.4c0,0.9,0,1.9,0,2.8H2
                          C2,8.1,2,8,2,7.9C2,7.1,2,6.3,2,5.4z M16.4,24.9H3.1c-0.8,0-1.2-0.3-1.2-1.1V10.1h24.2v6.6c-2.6-0.6-5-0.1-7,1.6
                          C17.1,20,16.2,22.2,16.4,24.9z M24.1,30c-3.2,0-5.9-2.7-5.9-5.9c0-3.2,2.7-5.9,5.9-5.9c3.3,0,5.9,2.7,5.9,5.9
                          C30,27.4,27.4,30,24.1,30z"/>
                        <path d="M6.6,16.6c-0.5,0-1.1,0-1.6,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0
                          c0.5,0,0.9-0.4,0.9-0.9C7.5,17,7.1,16.6,6.6,16.6z"/>
                        <path d="M12.1,16.6c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.8,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,1.1,0,1.6,0
                          c0.5,0,0.9-0.4,0.9-0.9C13,17,12.6,16.6,12.1,16.6z"/>
                        <path d="M17.6,16.6c-0.5,0-1,0-1.5,0c-0.5,0-1,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.8,0
                          c0.6,0,1-0.4,1-0.9C18.5,17,18.1,16.6,17.6,16.6z"/>
                        <path d="M12.1,12.4c-0.2,0-0.5,0-0.7,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0c-0.1,0-0.1,0-0.2,0
                          c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.3,0.9,0.8,1c0.6,0,1.2,0,1.7,0c0.5,0,0.9-0.5,0.8-0.9C13,12.8,12.6,12.4,12.1,12.4z"/>
                        <path d="M17.6,12.4c-0.5,0-1.1,0-1.6,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.3,0.9,0.8,0.9c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.9,0
                          c0.5,0,0.9-0.5,0.8-0.9C18.5,12.8,18.2,12.4,17.6,12.4z"/>
                        <path d="M21.5,14.2c0.6,0,1.2,0,1.7,0c0.5,0,0.9-0.5,0.8-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0
                          c-0.5,0-0.9,0.4-0.9,0.9C20.6,13.8,21,14.2,21.5,14.2z"/>
                        <path d="M6.6,20.8c-0.5,0-1.1,0-1.6,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.3,0.9,0.9,1c0.3,0,0.6,0,0.8,0v0c0.3,0,0.6,0,0.9,0
                          c0.5,0,0.9-0.5,0.8-0.9C7.5,21.2,7.1,20.8,6.6,20.8z"/>
                        <path d="M12.1,20.8c-0.3,0-0.5,0-0.8,0s-0.5,0-0.8,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.3,0.9,0.8,0.9c0.6,0,1.2,0,1.7,0
                          c0.5,0,0.9-0.5,0.8-0.9C13,21.2,12.6,20.8,12.1,20.8z"/>
                        <path d="M27.1,23.2c-0.7,0-1.3,0-2.1,0c0-0.7,0-1.4,0-2.1c0-0.6-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,1,0,1.9,0,2.9
                          c0,0.7,0.4,1.1,1.1,1.1c1,0,1.9,0,2.9,0c0.6,0,1.1-0.4,1.1-0.9C28.2,23.6,27.7,23.2,27.1,23.2z"/>
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[5].isActive ? "m-selected" : ""
                    }`} >{t("SCHEDULE")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("services")}>
                <Link className="nav-link " to="/services">
                  <span className={`nav-icon ${tabMenuViews[6].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <g>
                        <path d="M28.4,14.6c-1.3-0.7-2.7-0.2-3.5,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.5,1-0.8,1.4c-0.3,0.4-0.6,0.7-0.9,0.8
                            c-2.2,0.7-4.5,1.4-7.1,2.1c-0.1,0-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.4-0.5
                            c0.6-0.2,1.2-0.4,1.8-0.6c0.3-0.1,0.6-0.2,0.9-0.3c1.3-0.4,1.9-1.4,1.5-2.5c-0.3-1-1.3-1.5-2.6-1.1c-2.8,0.8-5,1.5-7,2.1
                            c-2.3,0.7-4,2.3-4.9,4.5C4.6,24,4,25.8,3.4,27.6c-0.4,1-0.7,2-1.1,3c-0.2,0.4-0.2,0.7,0,1c0.2,0.3,0.6,0.3,0.9,0.3c0.7,0,1.4,0,2,0
                            l1,0c0.3,0,0.5,0,0.8,0c0.6,0,1.2,0,1.8,0c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.6,0.5-1.1c0.1-1.4,0.8-2.2,2.4-2.7
                            c4.2-1.3,8.2-2.6,12.4-4.1c0.6-0.2,1.3-0.7,1.6-1.3c0.7-1.1,1.3-2.3,1.9-3.4l0.5-0.9C30,16.7,29.6,15.3,28.4,14.6z M13.6,21.2
                            c0.3,1,1.3,1.5,2.5,1.1c0.9-0.3,1.7-0.5,2.6-0.8c1.4-0.4,2.7-0.8,4.1-1.2c1.1-0.3,1.9-0.9,2.4-1.9c0.3-0.7,0.7-1.4,1.2-2.1
                            c0.2-0.3,0.5-0.5,0.7-0.6c0.2-0.1,0.5,0,0.7,0.1c0.6,0.4,0.7,0.8,0.4,1.4l-0.3,0.6c-0.7,1.3-1.4,2.6-2.1,3.9
                            c-0.2,0.3-0.6,0.5-0.9,0.7c-4,1.4-8.3,2.8-12.6,4.1c-1.8,0.6-2.7,1.6-2.9,3.2c-0.1,0.6-0.2,0.7-0.9,0.7c-0.7,0-1.4,0-2,0
                            c-0.4,0-0.9,0-1.3,0c-0.3,0-0.7,0-1.1,0c0.4-1,0.7-2,1-3l0.6-1.8c0.1-0.2,0.2-0.4,0.2-0.7c0.2-0.4,0.3-0.9,0.5-1.4
                            c0.8-2.5,2.4-4.1,5-4.8c1.6-0.4,3.2-0.9,4.7-1.4l1.5-0.5c0.6-0.2,0.8-0.2,0.9,0.2c0,0.1,0,0.3,0,0.3c-0.1,0.2-0.4,0.3-0.6,0.3
                            l-0.1,0c-0.3,0.1-0.7,0.2-1,0.3c-0.5,0.2-1.1,0.3-1.6,0.5C13.8,19.1,13.3,20.1,13.6,21.2z"/>
                        <path d="M13.2,7.6L13.2,7.6c0,0.6,0,1.2,0.7,1.5l0,0c0.3,0.1,0.7,0.3,0.7,0.4c0,0.1-0.2,0.5-0.4,0.8c-0.4,0.7,0,1.2,0.3,1.5
                            c0,0.1,0.1,0.1,0.1,0.2l0,0.1c0.3,0.4,0.7,1,1.5,0.7c0.3-0.1,0.6-0.1,0.7,0c0.2,0.1,0.2,0.4,0.2,0.6c0,1,0.7,1.2,1.2,1.4l0.1,0
                            c0.6,0.2,1.2,0.3,1.7-0.4c0.2-0.3,0.4-0.4,0.5-0.4c0,0,0,0,0,0c0.2,0,0.3,0.1,0.5,0.4c0.3,0.4,0.6,0.6,0.9,0.6
                            c0.3,0,0.5-0.1,0.8-0.2l0.1,0c0.5-0.2,1.3-0.4,1.2-1.4c0-0.3,0-0.5,0.2-0.6c0.1-0.1,0.3-0.1,0.6,0c0.9,0.3,1.4-0.2,1.7-0.7l0.1-0.1
                            c0.4-0.5,0.8-1.1,0.2-2c-0.2-0.2-0.2-0.4-0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.3c1-0.3,1-1,1-1.7l0-0.1c0-0.6,0-1.3-0.9-1.5
                            c-0.3-0.1-0.4-0.2-0.5-0.3c0-0.1,0-0.3,0.2-0.5c0.6-0.8,0.2-1.4-0.1-1.9c-0.3-0.4-0.8-1.1-1.8-0.8c-0.2,0.1-0.5,0.1-0.6,0
                            c-0.1-0.1-0.2-0.3-0.2-0.6c0.1-1-0.6-1.2-1.2-1.4L23,0.3c-0.4-0.2-1.2-0.5-1.8,0.4c-0.1,0.2-0.3,0.4-0.4,0.4
                            c-0.2,0-0.3-0.1-0.5-0.4c-0.1-0.1-0.5-0.6-1-0.6c-1.1-0.1-2,0.7-2.1,1.7c0,0.3-0.1,0.5-0.2,0.5c-0.1,0.1-0.2,0-0.4,0
                            c-0.1,0-0.3-0.1-0.4-0.1l-0.2,0c-0.5,0-1,0.4-1.4,0.9c-0.3,0.6-0.4,1.2-0.2,1.6c0.2,0.4,0.3,0.7,0.3,0.8c0,0.1-0.4,0.3-0.8,0.4
                            c-0.7,0.3-0.7,0.9-0.7,1.3C13.2,7.5,13.2,7.5,13.2,7.6z M15.9,4.5c-0.1-0.3-0.2-0.5-0.1-0.6c0.1-0.1,0.3-0.2,0.4-0.1
                            c1.7,0,2-0.2,2.4-1.8c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0,0.4,0.1c1.2,1,1.7,1,2.9,0c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0,0.2,0.2,0.3,0.4
                            c0.4,1.6,0.8,1.9,2.3,1.8c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.3,0,0.5c-0.6,1.3-0.4,2,0.9,2.8c0.2,0.1,0.3,0.3,0.3,0.4
                            c0,0.2-0.1,0.3-0.4,0.4c-1.1,0.6-1.4,1.5-0.9,2.6c0.1,0.2,0.1,0.5,0,0.6c0,0.1-0.3,0.2-0.5,0.1c-1.2-0.2-1.9,0.4-2.2,1.6
                            c-0.1,0.2-0.2,0.5-0.3,0.5c-0.1,0-0.4-0.1-0.5-0.2c-0.9-0.9-1.8-0.9-2.7,0c-0.2,0.1-0.4,0.3-0.5,0.3c0,0,0,0,0,0
                            c-0.1-0.1-0.3-0.3-0.3-0.6c-0.2-1-0.9-1.6-1.9-1.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.6-0.2c0-0.1,0-0.4,0.1-0.5
                            c0.5-1.1,0.2-2-0.8-2.6c-0.2-0.1-0.4-0.4-0.4-0.5c0-0.1,0.2-0.3,0.4-0.4l0,0C16.1,6.5,16.4,5.6,15.9,4.5z"/>
                        <path d="M18.8,9.8c0.4,0.4,0.8,0.8,1.2,1.2l0,0c0.2,0.3,0.5,0.4,0.7,0.4c0,0,0,0,0,0c0.2,0,0.5-0.1,0.7-0.3
                            c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.1,1.7-1.7c0.7-0.7,0.8-1.9,0.2-2.7c-0.5-0.8-1.6-1.2-2.6-0.9c-0.3,0.1-0.5,0.2-0.8,0.4
                            c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-1.1-0.7-2.4-0.6-3.1,0.4c-0.7,0.9-0.6,2.1,0.3,3.1C18,9.1,18.4,9.4,18.8,9.8z
                            M18.5,6.5c0.2-0.2,0.4-0.3,0.6-0.3c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.3c0.4,0.4,0.7,0.6,1.1,0.6c0.4,0,0.7-0.2,1.1-0.6
                            c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.6,0.3c0.4,0.4,0.2,0.8,0,1.1c-0.5,0.5-1,1.1-1.6,1.7l-0.6,0.7l-0.6-0.7
                            c-0.5-0.6-1-1.1-1.5-1.6C18.1,7.1,18.3,6.7,18.5,6.5z"/>
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[6].isActive ? "m-selected" : ""
                    }`} >{t("SERVICES")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("professionals")}>
                <Link className="nav-link " to="/professionals">
                  <span className={`nav-icon ${tabMenuViews[7].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <path d="M31.8,22.6c0-0.5,0-0.9,0-1.4v0c0-1.6-0.8-2.7-2.3-3.3c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.3-1.4-0.5
                        c-0.6-0.2-1.2-0.5-1.7-0.8c0-0.1-0.1-0.3-0.1-0.3c0.4-0.6,0.9-1.2,1.3-1.7l0.2-0.3c0.6-0.8,1.3-1.7,1-3c0-0.2-0.1-0.4-0.1-0.7
                        c0-0.2,0-0.4-0.1-0.6c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.7-0.1-1.5-0.5-2.1c-0.8-1.2-2-1.9-2.7-2.3c-1.8-0.9-5.7,0.3-6.9,1.9L17,6.4
                        l0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c-0.3,0-0.6-0.1-0.9,0c-1.2,0.2-2.4,0.5-3.5,0.9c-1.8,0.6-2.8,2-2.9,4.3c0,0.2,0,0.3,0,0.5
                        c0,0.4,0,0.8-0.1,1.1c-0.5,1.6,0.2,2.7,0.9,3.5l0.3,0.3c0.3,0.4,0.6,0.7,0.9,1.1c0.2,0.3,0.4,0.6,0.3,0.8c0,0.2-0.2,0.4-0.6,0.7
                        c-0.4,0.3-0.9,0.4-1.4,0.6l-0.2,0.1c-0.4,0.1-0.7,0.3-1.1,0.4c-0.5,0.2-1.1,0.4-1.6,0.6C7,22,6.5,22.4,6.2,22.9
                        c0-0.2-0.1-0.4-0.1-0.6L6,22H2.2c-0.1-1.6,0.4-2.3,2-2.8c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.2,1.2-0.4l0.1,0
                        c0.7-0.3,1.4-0.7,2-1.2c0.9-0.8,1.1-1.9,0.6-2.8c-0.2-0.5-0.6-0.9-0.9-1.2c-0.2-0.2-0.4-0.5-0.6-0.7L7,12.3
                        c-0.3-0.4-0.6-0.8-0.6-1.2c0-0.6,0.1-1.2,0.2-1.9c0-0.2,0.1-0.4,0.1-0.6C6.8,7.7,7,7.1,7.6,6.9c1-0.4,2.2-0.7,3.6-1
                        c0.5-0.1,0.9,0.1,1.2,0.7c0.2,0.5,0.9,0.7,1.3,0.7c0.4,0,0.6-0.2,0.9-0.4c0.1-0.1,0.1-0.1,0.2-0.2l0.5-0.4l-0.5-0.4
                        c-0.2-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.8-1.1-1.9-1.5-3.4-1.3C9.4,4.1,8.4,4.5,7.3,4.9C6,5.4,5.1,6.4,4.9,8
                        C4.8,8.3,4.8,8.7,4.8,9c0,0.5-0.1,0.9-0.2,1.3c-0.4,1.5,0.2,2.5,0.8,3.2l0.3,0.4c0.3,0.3,0.5,0.6,0.8,1c0.2,0.2,0.4,0.6,0.3,0.9
                        c-0.1,0.3-0.4,0.5-0.6,0.6c-0.7,0.3-1.5,0.6-2.2,0.9l-0.3,0.1c-2.9,1-4.1,3.1-3.4,6.2l0.1,0.4l1.8,0c1,0,1.9,0,2.9,0c0,0,0,0,0,0
                        c0.1,0,0.4,0,0.6-0.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.7-0.2,1.4-0.3,2.2c0,0.4-0.1,0.7-0.1,1.1l-0.1,0.5h18.8c1.3,0,1.9,0,2.3-0.4
                        c0.4-0.4,0.4-1,0.4-2.3c0-0.6-0.1-1.1-0.3-1.6h5.6L31.8,22.6z M14.3,19.8c0.2-0.6,0.1-1.2-0.2-1.7c-0.5-0.8-1-1.5-1.6-2
                        c-0.7-0.7-1-1.2-0.7-2c0.2-0.5,0.2-1.1,0.2-1.7c0-0.3,0-0.6,0-0.9c0.1-1,0.6-1.6,1.4-1.8c0.9-0.3,2.2-0.6,3.4-0.8
                        c0.6-0.1,1,0.2,1.3,0.8c0.1,0.3,0.4,0.6,0.8,0.7c0.7,0.1,0.8,0.5,0.9,1.3l0,0.4c0.1,0.9,0.2,1.8,0.1,2.6c0,0.4-0.4,0.9-0.7,1.3
                        c-0.3,0.3-0.5,0.7-0.8,1c-0.4,0.5-0.9,1-1.3,1.6c-0.3,0.6-0.1,1.7,0.4,2.2c0.5,0.5,1.1,1,2.1,1.4c0.5,0.2,1.1,0.4,1.6,0.6
                        c0.5,0.1,0.9,0.3,1.4,0.5c1.7,0.7,2,1.2,1.9,2.9H7.3c-0.2-1.6,0.4-2.4,1.9-3c0.3-0.1,0.7-0.2,1-0.4c0.8-0.3,1.7-0.6,2.5-1
                        C13.4,21.4,14.1,20.6,14.3,19.8z M24.2,21.7c-0.6-0.3-1.3-0.5-1.9-0.7c-0.6-0.2-1.2-0.4-1.7-0.6c-0.5-0.2-1-0.5-1.3-0.8
                        c0-0.1,0-0.2,0-0.3c0.2-0.3,0.5-0.7,0.8-1c0.4-0.4,0.7-0.9,1-1.4c0.5-0.9,0.9-1.7,1-2.5c0.1-0.6,0-1.2-0.1-1.8
                        c0-0.3-0.1-0.6-0.1-0.9c-0.1-1.7-1.7-4-3.4-4.7c0.2,0,0.4-0.1,0.5-0.2c1-0.4,2.1-0.8,3.5-1.2c0.7-0.2,1.2,0.1,1.5,0.7
                        c0.1,0.2,0.3,0.6,0.7,0.6c0.7,0.1,0.9,0.5,0.9,1.6c0,0.2,0,0.4,0,0.6c-0.1,0.5-0.1,0.9,0.1,1.4c0.2,0.6,0.1,0.9-0.3,1.4
                        c-0.5,0.7-1.1,1.4-1.8,2.2c-0.9,1-0.9,2,0.1,2.8c0.6,0.5,1.4,0.9,2,1.2c0.5,0.2,1,0.4,1.5,0.6c0.6,0.2,1.1,0.4,1.7,0.7
                        c1.1,0.5,1.4,1.2,1.1,2.4l-0.7,0c-0.7,0-1.4,0-2.1,0c-0.4,0-0.8,0-1,0.3c-0.2,0.3-0.2,0.6-0.1,1.1C25.6,22.6,25,22.1,24.2,21.7z"/>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[7].isActive ? "m-selected" : ""
                    }`} >{t("PROFESSIONAL")}</span>
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleTabClick("promocode")}>
                <Link className="nav-link " to="/promocode">
                  <span className={`nav-icon ${tabMenuViews[8].isActive ? "m-selected" : ""
                    }`}>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      className="bi bi-house-door"
                      fill="currentColor">
                      <g>
                        <path className="st0" d="M29.5,17.9c-0.6-1.2-0.8-2.2,0-3.5c0.6-0.9,1-1.9,0.8-3.1c-0.2-1.8-1.2-3-3-3.3c-1.8-0.4-2.9-1.3-3.2-3.1
                          c-0.1-0.3-0.2-0.5-0.3-0.7c-1-1.9-2.8-2.6-4.8-1.9c-0.6,0.3-1.3,0.5-1.9,0.8c-0.8,0.4-1.7,0.4-2.5-0.1c-0.9-0.6-1.9-1-3-1
                          C9.8,1.9,8.6,2.6,8,4.5C7.4,6.4,6.3,7.7,4.3,8.3c-2.5,0.8-3.3,3-2,5.2c0.9,1.7,0.9,3.2,0,4.7C2.1,18.5,2.1,18.7,2,19
                          c-0.6,2,0.1,4,2.1,4.6c2.1,0.6,3.3,1.8,4.1,3.9c0.7,2.3,3,3.2,5.1,2c1.9-1,3.7-1.2,5.6,0.1c0,0,0.1,0,0.1,0.1
                          c2.2,0.9,4.5,0,5.2-2.2c0.6-2,1.7-3.2,3.7-3.7c1.8-0.5,2.6-1.9,2.6-3.4C30.4,19.4,29.9,18.6,29.5,17.9z M26.9,19.3
                          c0.9,1.7,0.7,2.1-0.9,2.7c-2.4,0.7-3.9,2.1-4.5,4.5C21.3,27.3,21,28,20.1,28c-0.7,0-1.3-0.4-1.8-0.6c-1.8-0.9-3.4-0.9-5.2,0
                          c-2.1,1.1-2.6,0.9-3.3-1.3c-0.6-2-1.9-3.3-4.1-4c-1.9-0.6-2.1-1.2-1.2-3c1.2-2,1.2-4,0-6c-0.9-1.6-0.6-2.3,1.1-2.8
                          c2.4-0.6,3.8-2,4.5-4.5c0.5-1.7,0.8-1.8,2.3-0.9C14.3,6,16.3,6.2,18.3,5c1.9-1,2.5-0.8,3.2,1.2c0.6,2.1,1.9,3.4,4.1,4.1
                          c2,0.6,2.2,1.2,1.2,3C25.7,15.4,25.7,17.3,26.9,19.3z"/>
                        <path className="st0" d="M20.6,11.3c-0.1-0.5-0.3-0.8-0.7-1c-0.7-0.2-1.3,0.1-1.8,0.6c-2.2,3-4.4,5.9-6.6,8.9c-0.6,0.7-0.6,1.4,0.2,1.9
                          c0.8,0.6,1.3,0,1.8-0.6c2.1-2.9,4.4-5.8,6.5-8.6C20.4,12.2,20.6,11.8,20.6,11.3z"/>
                        <path className="st0" d="M19.5,17.3c-1.1-0.1-2.4,1.1-2.5,2.3c-0.1,1.3,1.1,2.5,2.4,2.5c1.4,0,2.3-1,2.4-2.3
                          C21.9,18.4,20.8,17.3,19.5,17.3z"/>
                        <path className="st0" d="M14.8,12.6c0-1.2-1.1-2.4-2.4-2.4c-1.4,0-2.4,1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4C13.6,15,14.8,13.8,14.8,12.6z"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className={`nav-link-text ${tabMenuViews[8].isActive ? "m-selected" : ""
                    }`} >{t("PROMOCODE")}</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Menubar;
