import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeServices from "../../services/Home/Home.service";

function Privacy_data_exclusion_policy() {
  const { t, i18n } = useTranslation();
  const [pageContent, setPageContent] = useState(undefined);
  const params = useParams();

  useEffect(() => {
    let payload = `privacy_data_exclusion_policy`;

    HomeServices.pages(payload)
      .then((response) => {
        if (response.data.status) {
          let content = response.data.data;
          let languageType = params.lang ? params.lang : i18n.language;
          if (languageType === "pt") {
            setPageContent(content.content_pt);
          } else {
            setPageContent(content.content);
          }
        }
      })
      .catch();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [i18n.language]);


  return (
    <div>
      <section
        className="terms-condition-section Privacy_data_Exclusion_policy"
        style={{
          backgroundImage: `url('/assets/img/expert-img-2.png')`
        }}
      >
        <div className="container">
          <h2 className="title-a">{ t('PRIVACY_DATA_EXCLUSION_POLICY') }</h2>
        </div>  
      </section>
      <h6 className="title-a Subitle text-center">{ t('PRIVACY_DATA_EXCLUSION_POLICY_SUBTITLE') }</h6>  
      
      <section className="tc-content section-tb8 Privacy_data_Exclusion_policy_textData">
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: pageContent }} />
        </div>
      </section>
    </div>
  );
}

export default Privacy_data_exclusion_policy;
