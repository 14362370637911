export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SOCIAL_REGISTER_CHECK = "SOCIAL_REGISTER_CHECK";
export const SOCIAL_REGISTER_CANCEL = "SOCIAL_REGISTER_CANCEL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const VERIFY_MAIL_SEND_SUCCESS = "VERIFY_MAIL_SEND_SUCCESS";
export const VERIFY_MAIL_SEND_FAIL = "VERIFY_MAIL_SEND_FAIL";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_FAIL = "RESET_PWD_FAIL";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";
export const PROFILE_PHOTO_UPDATE_SUCCESS = "PROFILE_PHOTO_UPDATE_SUCCESS";
export const PROFILE_PHOTO_UPDATE_FAIL = "PROFILE_PHOTO_UPDATE_FAIL";
export const LANGUAGE_SELECTOR_SUCCESS = "LANGUAGE_SELECTOR_SUCCESS";
export const LANGUAGE_SELECTOR_FAIL = "LANGUAGE_SELECTOR_FAIL";
